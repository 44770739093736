import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Img from 'components/image';
import Pill from '@youship/components/objects/pill';
import Steps from 'components/steps';

import carIllustration from 'images/be-carrier/car_illustration.svg';
import companyIllustration from 'images/be-carrier/company_illustration.svg';

import './styles.scss';

const applyStep1Image = 'be-carrier/apply_step_1.png';
const applyStep2Image = 'be-carrier/apply_step_2.png';
const applyStep3Image = 'be-carrier/apply_step_3.png';

const STEPS = [
  {
    index: 1,
    title: 'transporter.apply.s1.title',
    description: 'transporter.apply.s1.description',
    image: applyStep1Image
  },
  {
    index: 2,
    title: 'transporter.apply.s2.title',
    description: 'transporter.apply.s2.description',
    image: applyStep2Image
  },
  {
    index: 3,
    title: 'transporter.apply.s3.title',
    description: 'transporter.apply.s3.description',
    image: applyStep3Image
  }
];

const requirementsImage = 'be-carrier/requirements.png';

const BeCarrierRegistrationDetails = () => {
  const intl = useIntl();

  return (
    <div className="be-carrier-registration-details">
      <div className="container">
        <div className="row be-carrier-registration-details__row">
          <div className="col col-12 offset-0 col-lg-6 offset-lg-6 col-xl-6 offset-xl-5 order-lg-2 be-carrier-registration-details__image-column">
            <Img
              alt="Requirements"
              classNames="be-carrier-registration-details__image"
              src={requirementsImage}
            />
          </div>
          <div className="col col-12 col-lg-6 col-xl-4 order-lg-1">
            <h6>
              <FormattedMessage id="transporter.requirements.title" />
            </h6>
            <h2 className="h2--display be-carrier-registration-details__description">
              <FormattedMessage id="transporter.requirements.description" />
            </h2>
          </div>
        </div>
        <Pill
          classNames="be-carrier-registration-details__pill"
          text={intl.formatMessage({ id: 'transporter.requirements.text' })}
        />
        <div className="row">
          <div className="col col-12 col-lg-5">
            <div className="be-carrier-registration-details__requirement be-carrier-registration-details__requirement--margin">
              <img
                alt="Who can become a transporter"
                className="be-carrier-registration-details__requirement-illustration"
                src={companyIllustration}
              />
              <div className="be-carrier-registration-details__text">
                <div className="be-carrier-registration-details__requirement-title">
                  <FormattedMessage id="transporter.requirements.req1.title" />
                </div>
                <div className="be-carrier-registration-details__requirement-description">
                  <FormattedMessage id="transporter.requirements.req1.description" />
                </div>
              </div>
            </div>
          </div>
          <div className="col col-12 col-lg-5">
            <div className="be-carrier-registration-details__requirement">
              <img
                alt="What you’ll need"
                className="be-carrier-registration-details__requirement-illustration"
                src={carIllustration}
              />
              <div className="be-carrier-registration-details__text">
                <div className="be-carrier-registration-details__requirement-title">
                  <FormattedMessage id="transporter.requirements.req2.title" />
                </div>
                <div className="be-carrier-registration-details__requirement-description">
                  <FormattedMessage id="transporter.requirements.req2.description" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="be-carrier-registration-details__separator" />
        <Pill
          classNames="be-carrier-registration-details__pill"
          text={intl.formatMessage({ id: 'transporter.apply.title' })}
        />
        <Steps
          steps={STEPS}
        />
      </div>
    </div>
  );
};

export default BeCarrierRegistrationDetails;
