import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Img from 'components/image';

import './styles.scss';

const Steps = ({ classNames, steps }) => (
  <div className={`steps${classNames ? ` ${classNames}` : ''}`}>
    <div className="row">
      {Array.isArray(steps) && steps.map((step, index) => (
        <div
          key={index}
          className="col col-12 col-sm-4"
        >
          <div className="steps__step steps__step--margin">
            {step.image && step.index && (
              <div className="steps__step-image-wrapper">
                <Img
                  alt={step.title}
                  className="steps__step-image"
                  src={step.image}
                />
                <div className="steps__step-number">
                  {step.index}
                </div>
              </div>
            )}
            {step.title && (
              <div className="steps__step-title">
                <FormattedMessage id={step.title} />
              </div>
            )}
            {step.description && (
              <div className="steps__step-description">
                <FormattedMessage id={step.description} />
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  </div>
);

Steps.propTypes = {
  classNames: PropTypes.string,
  steps: PropTypes.arrayOf(PropTypes.shape({
    index: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired
  }))
};

Steps.defaultProps = {
  classNames: null,
  steps: null
};

export default Steps;
