import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

//import Cookies from 'universal-cookie';
import Cookies from './../../../node_modules/universal-cookie';

import Loader from '@youship/components/objects/loader';

import SuccessIllustration from 'images/be-carrier/register_form_success_illustration.inline.svg';

import PersonalInformationStep from './personal-information-step';
import ConfirmationCodeStep from './confirmation-code-step';

import './styles.scss';

const cookies = new Cookies();

const WEB_APP_URL = process.env.GATSBY_WEB_APP_URL;

const steps = [PersonalInformationStep, ConfirmationCodeStep];
const PERSONAL_INFORMATION_TITLE = 'transporter.form.title_personal';
const PHONE_ACTIVATION_TITLE = 'transporter.form.title_activation';

const CarrierRegistrationForm = ({ classNames }) => {
  const [isUserAuthenticated, setUserAuthenticated] = useState(!!cookies.get('AUTH_TOKEN'));
  const [userRequiresPhoneActivation, setUserRequiresPhoneActivation] = useState(cookies.get('USER_REQUIRES_PHONE_ACTIVATION') === 'true');
  const [currentStep, setCurrentStep] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [succeeded, setSucceeded] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);

  useEffect(
    () => {
      if (isUserAuthenticated && userRequiresPhoneActivation && currentStep !== 2) {
        setCurrentStep(2);
      }
    },
    // currentStep is not required for this hook
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isUserAuthenticated, userRequiresPhoneActivation]
  );

  const handleStepSuccess = (message) => {
    window.scrollTo(0, 0);
    setIsSubmitting(false);

    if (currentStep !== steps.length) {
      setCurrentStep(prevState => prevState + 1);
    } else {
      setSucceeded(true);
      setUserAuthenticated(!!cookies.get('AUTH_TOKEN'));
      setUserRequiresPhoneActivation(cookies.get('USER_REQUIRES_PHONE_ACTIVATION') === 'true');
      window.location = `${WEB_APP_URL}/account-confirmation/carrier`;

      if (message) {
        setSuccessMessage(message);
      }
    }
  };

  const handleStepError = () => {
    setIsSubmitting(false);
  };

  const Step = steps[currentStep - 1];

  let formSubTitle = null;

  switch (currentStep) {
    case 1:
      formSubTitle = PERSONAL_INFORMATION_TITLE;
      break;
    case 2:
      formSubTitle = PHONE_ACTIVATION_TITLE;
      break;
    default:
      break;
  }

  return (
    <div className={`carrier-registration-form${classNames ? ` ${classNames}` : ''} ${succeeded ? ' carrier-registration-form--success' : ''}`}>
      {isUserAuthenticated && !userRequiresPhoneActivation ? (
        <div className="carrier-registration-form__success">
          <SuccessIllustration
            alt="Form success"
            className="carrier-registration-form__success-image"
          />
          <div className="carrier-registration-form__success-text">
            <div className="carrier-registration-form__success-title">
              <FormattedMessage id="transporter.form.success_title" />
            </div>
            <div className="carrier-registration-form__success-wrapper">
              <span className="carrier-registration-form__success-description">
                <FormattedMessage id="transporter.form.authenticated_signin_title" />
              </span>
              <Link
                className="carrier-registration-form__footer-link"
                to={`${WEB_APP_URL}/account-confirmation/carrier`}
              >
                {' '}
                <FormattedMessage id="transporter.form.authenticated_signin" />
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <>
          {isSubmitting && (
            <div className="carrier-registration-form__loader-wrapper">
              <Loader />
            </div>
          )}
          <div className="carrier-registration-form__header">
            <div className="carrier-registration-form__title">
              <FormattedMessage id="transporter.form.title" />
            </div>
            <div className="carrier-registration-form__steps">
              <FormattedMessage id="partners.form.step" />
              {' '}
              {currentStep}
              /
              {steps.length}
            </div>
          </div>
          {formSubTitle && (
            <div className="carrier-registration-form__subtitle">
              <FormattedMessage id={formSubTitle} />
            </div>
          )}
          <Step
            classNames={succeeded ? ' carrier-registration-form__step--hide' : ''}
            successMessage={currentStep === 2 ? successMessage : null}
            onError={handleStepError}
            onSubmitting={() => setIsSubmitting(true)}
            onSuccess={handleStepSuccess}
          />
          {currentStep === 1 && (
            <>
              <hr className="carrier-registration-form__separator" />
              <div className="carrier-registration-form__footer">
                <span className="carrier-registration-form__footer-text">
                  <FormattedMessage id="transporter.form.already_started" />
                </span>
                <Link
                  className="carrier-registration-form__footer-link"
                  to={`${WEB_APP_URL}/account-confirmation/carrier`}
                >
                  {' '}
                  <FormattedMessage id="transporter.form.finish" />
                </Link>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

CarrierRegistrationForm.propTypes = {
  classNames: PropTypes.string
};

CarrierRegistrationForm.defaultProps = {
  classNames: null
};

export default CarrierRegistrationForm;
