import React from 'react';

import Link from 'utils/localized-link';

import Banner from 'components/sections/banner';
import BeCarrierFaq from 'components/sections/be-carrier-faq';
import BeCarrierHeader from 'components/sections/be-carrier-header';
import BeCarrierRegistrationDetails from 'components/sections/be-carrier-registration-details';
import BeCarrierStart from 'components/sections/be-carrier-start';
import Benefits from 'components/sections/benefits';
import Layout from 'components/layout';
import SEO from 'components/seo';

import ClockIcon from 'images/icons/clock.inline.svg';
import DollarSignIcon from 'images/icons/dollar-sign.inline.svg';
import MultiCheckboxIcon from 'images/icons/multi-checkbox.inline.svg';


const WEB_APP_URL = process.env.GATSBY_WEB_APP_URL;

const BENEFITS = [
  {
    title: 'transporter.benefits.b1.title',
    description: 'transporter.benefits.b1.description',
    icon: ClockIcon
  },
  {
    title: 'transporter.benefits.b2.title',
    description: 'transporter.benefits.b2.description',
    icon: MultiCheckboxIcon
  },
  {
    title: 'transporter.benefits.b3.title',
    description: 'transporter.benefits.b3.description',
    icon: DollarSignIcon
  }
];

const bannerButtons = [
  {
    external: true,
    linkProps: {
      //to: `${WEB_APP_URL}/register`
      to: '#register'
    },
    noNewTab: true,
    light: true,
    noArrow: true,
    small: true,
    text: 'transporter.banner.buttons.sign_up'
  },
  {
    linkComponent: Link,
    linkProps: {
      to: '/contacts'
    },
    light: true,
    noArrow: true,
    noBackground: true,
    small: true,
    text: 'transporter.banner.buttons.contacts'
  }
];

const bannerImage = 'create-account.png';

const BeCarrierPage = ({ pageContext: { locale } }) => (
  <Layout locale={locale}>
    <SEO title="transporter.title" />
    <BeCarrierHeader />
    <Benefits
      benefits={BENEFITS}
      title="transporter.benefits.title"
    />
    <BeCarrierRegistrationDetails />
    <BeCarrierStart />
    <BeCarrierFaq />
    <Banner
      buttons={bannerButtons}
      image={bannerImage}
      text="transporter.banner.text"
      title="transporter.banner.title"
    />
  </Layout>
);

export default BeCarrierPage;
